// sticky sidebar

require("sticky-kit/dist/sticky-kit.min.js");

export function initStickySidebar() {
  $(document).ready(function() {
    var mainColumn = "#js-main-column";
    var sideColumn = "#js-side-column";
    if ($(mainColumn).innerHeight() > $(sideColumn).innerHeight()) {
      $(sideColumn).stick_in_parent({
        parent: "#js-main-container",
        spacer: "#js-sticky-spacer"
      });
    }
  });
}
