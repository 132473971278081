import {
  toggleNetworkModal,
  toggleSearchBar,
  resizeTrendingTopics
} from "./init";

import { isiPadOriPod } from "./utility";

import { initStickySidebar } from "./sticky";

toggleNetworkModal();
toggleSearchBar();
resizeTrendingTopics();

if (!isiPadOriPod()) {
    initStickySidebar();
}
